// Mobx
import { action, decorate, observable } from 'mobx'
import { ignore } from 'mobx-sync'
// Services
import UserService from 'services/userService'
// Store
import authStore from '../authStore'
// Analytics
import { track } from 'utils/analytics'
// i18n
import t from 'i18n'
import { supportedCountries } from 'utils/utils'
import { setStatusInDebts, getDebtFilter } from 'utils/debt'
import { events } from 'utils/events'

const userService = new UserService()

class UserProgramStore {
  userProgram = {}
  loading = false
  downloading = false
  trackerId = null
  debtsFilter = []

  loadResumen = async () => {
    try {
      this.loading = true
      const { id } = authStore.account
      const token = authStore.accessToken
      const { data, status } = await userService.getResumen(id, token)
      this.loading = false

      if (status && data.user) {
        const {
          user: { program }
        } = data
        const debts = program?.credit_repair?.debts || []
        const country = data?.user?.program?.credit_repair?.country || ''

        if (
          authStore.hasUserPartialCredit({
            hasCredit: program?.credit_repair?.has_credit,
            status: program?.credit_repair?.status
          }) ||
          !supportedCountries(country)
        ) {
          authStore.cleanAccess()
        }

        const buildDebts = setStatusInDebts(debts)

        const debtFilter = getDebtFilter({ buildDebts, country })

        this.debtsFilter = [
          { value: '', text: t('debts.filters.all') },
          ...debtFilter
        ]
        this.userProgram = program
        this.trackerId = program.credit_repair?.tracker_id

        return { success: true }
      }
      this.cleanUserProgram()

      return { success: false }
    } catch (error) {
      this.loading = false
      return { success: false, error: error?.response?.data?.message || '' }
    }
  }

  cleanUserProgram = () => {
    this.userProgram = {}
  }

  getPaymentReference = async () => {
    try {
      this.downloading = true
      const { id } = authStore.account
      const token = authStore.accessToken
      const { status, data } = await userService.getPaymentReference(id, token)

      track(events.USER_PAYMENT_REFERENCE, this.trackerId, null, {
        status: status
      })

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'Talon_de_Aportaciones_RTD.pdf'
        document.body.appendChild(a)
        a.click()
        a.remove()
        this.downloading = false
        return { success: true }
      }
      this.downloading = false
      return { success: false }
    } catch (error) {
      this.downloading = false
      return { success: false, error: error?.response?.data?.message || '' }
    }
  }
}

decorate(UserProgramStore, {
  loading: [observable, ignore],
  loadResumen: action,
  getPaymentReference: action,
  cleanUserProgram: action,
  userProgram: observable,
  trackerId: observable,
  debtsFilter: observable,
  downloading: [observable, ignore]
})

export default new UserProgramStore()
