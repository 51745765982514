import t from '../i18n'

export const NON_CLOSED_ACCOUNT_DOCUMENT_REQUESTED_STATUS =
  'non_closed_account_document_requested'

export const CO = 'co'

export const MX = 'mx'

export const ES = 'es'

export const SUPPORTED_COUNTRIES = [MX, ES, CO]

export const MINIMUM_DEBT_AMOUNT = 20000

export const SIMULATOR_COL_SIZES = {
  normal: {
    col1: 4,
    col2: 8
  },
  compact: {
    col1: 5,
    col2: 7
  }
}

export const AGENTS = {
  fer: {
    displayName: 'Fer',
    image: './assets/help/fer.png'
  },
  vane: {
    displayName: 'Vane',
    image: './assets/help/vane.png'
  }
}

export const STEPS_LIST = [
  {
    title: 'Tu identidad'
  },
  {
    title: 'Tu beneficiario'
  },
  {
    title: 'Tus finanzas'
  },
  {
    title: 'Tu buró'
  },
  {
    title: 'Tus deudas'
  },
  {
    title: 'Tu plan'
  },
  {
    title: 'Tu resumen'
  },
  {
    title: 'Tu contrato'
  },
  {
    title: 'Tu pago'
  }
]

export const OTP_STATUS = ['sms_sended', 'email_sended']

export const INVALID_STATUS = ['inactive', 'drop', 'deprecated']

export const DATE_FORMATS = ['months', 'days']

export const PROGRAM_STATUS = {
  active: 'active',
  graduated: 'graduated',
  dropRequested: 'drop_requested',
  earlyDrop: 'early_drop',
  partialCredit: 'partial_credit'
}

export const TIMELINE_STATUS = {
  CONTRACT_SIGNING: 'contract_signing',
  PROGRAM_START: 'program_start',
  PAID_DEBTS: 'paid_debts',
  MONTHLY_PAYMENTS_MADE: 'monthly_payments_made',
  LATEST_ACTIVITY: 'latest_activity',
  PROGRAM_GRADUATION: 'program_graduation'
}

export const RESUME_STATUS = {
  good: 'good',
  regular: 'regular',
  bad: 'bad'
}

export const FORM_TYPE = {
  sign_in: 'sign_in',
  login: 'login',
  forgot_password: 'forgot_password',
  validate_otp: 'validate_otp',
  create_password: 'create_password'
}

export const SUPPORT_EMPLOYEE_MESSAGES = {
  sign_in: t('support_employee.sign_in.floating.dialog'),
  login: t('support_employee.login.floating.dialog'),
  forgot_password: t('support_employee.forgot_password.floating.dialog'),
  create_password: t('support_employee.create_password.floating.dialog')
}

export const NUMERALS = {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: t('numeral.abbreviations.thousand'),
    million: t('numeral.abbreviations.million')
  },
  currency: {
    symbol: '$'
  }
}

export const APP_URL_STORES = {
  google:
    'https://play.google.com/store/apps/details?id=com.resuelvetudeuda.app',
  apple: {
    mx: 'https://apps.apple.com/mx/app/resuelve-tu-deuda/id1144698544',
    co: 'https://apps.apple.com/co/app/resuelve-tu-deuda/id1144698544',
    es: 'https://apps.apple.com/es/app/resuelve-tu-deuda/id1144698544'
  },
  huawei: 'https://appgallery.huawei.com/#/app/C102538685'
}

export const RTD_INFO = {
  phoneNumber: '800 4793 276'
}

export const LOG_ROCKET_TYPE_ERRORS = {
  otp: 'otp',
  login: 'login',
  signIn: 'signIn',
  signInInvalidStatus: 'signInInvalidStatus'
}

export const DEBT_STATUS = {
  new: 'new',
  medium: 'medium',
  high: 'high',
  negotiation: 'negotiation',
  liquidation: 'liquidation',
  paid: 'paid',
  cancelled: 'cancelled',
  closed: 'closed',
  non_closed_account: 'non_closed_account',
  non_closed_account_payment: 'non_closed_account_payment',
  liquidation_structured_payment: 'liquidation_structured_payment',
  liquidation_portfolio_payment: 'liquidation_portfolio_payment',
  liquidation_outside_of_program: 'liquidation_outside_of_program',
  non_closed_account_document_requested: 'non_closed_account_document_requested'
}

export const DEBT_SUB_STATUS = {
  debt_assignment: 'debt_assignment',
  projecting_negotiation: 'projecting_negotiation',
  negotiation_strategy: 'negotiation_strategy',
  preparing_negotiation: 'preparing_negotiation',
  negotiation: 'negotiation',
  earned_discount: 'earned_discount',
  liquidation_structured_payment: 'liquidation_structured_payment',
  liquidation_in_process: 'liquidation_in_process',
  liquidated: 'liquidated',
  liquidated_with_credit: 'liquidated_with_credit',
  closed: 'closed',
  refinancing: 'refinancing',
  cancelled: 'cancelled'
}

export const DEFAULT_LOCALE = MX

export const LOCALES_AVAILABLE = `${MX}|${ES}|${CO}`

export const BAD_STATUS_DAYS = 2

export const REGULAR_STATUS_DAYS = 9

export const TIMER_INIT = 59

export const SETTLEMENT_STATUS_PROCESS = [
  'pending_money_request',
  'money_request_approved',
  'pending_payment'
]

// Sub states: Visualizar sub etapas para reparadoras de México.
export const SUB_STATE_COUNTRIES_FILTER = ['mx']

// Sub states: Visualizar estatus de negociación para reparadoras de España y Colombia.
export const STATUS_COUNTRIES_FILTER = ['es', 'co']
