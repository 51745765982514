import React, { Component, Suspense } from 'react'
// Components
import Loader from 'components/molecules/loader'
import AuthFooter from 'components/organisms/authFooter'
import CustomButton from 'components/molecules/button'
// Material ui
import { Box, Container, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// i18n
import t from 'i18n'
// Sentry
import { sentryCaptureMessage } from 'utils/sentry'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false
    }
  }

  static getDerivedStateFromError (error = '') {
    return { hasError: true, error }
  }

  componentDidCatch (_, errorInfo) {
    this.setState({ errorInfo })

    const errorMessage = errorInfo.componentStack.toString()

    if (errorMessage) {
      sentryCaptureMessage(errorMessage)
    }
  }

  render () {
    const { hasError, errorInfo } = this.state
    const { classes, children } = this.props

    if (!hasError) return children

    return (
      <Suspense fallback={<Loader />}>
        <Box className={classes.root} display='flex' alignItems='center'>
          <Container className={classes.container} disableGutters>
            <Box className={classes.content}>
              <Box className={classes.errorContent}>
                <Typography variant='h1' className={classes.title}>
                  {t('error_boundary.title')}
                </Typography>
                <details open>
                  <summary className={classes.seeDetails}>
                    {t('error_boundary.see_details')}
                  </summary>
                  {errorInfo && errorInfo.componentStack.toString()}
                </details>
                <Box className={classes.buttonContent}>
                  <CustomButton
                    fullWidth
                    text={t('error_boundary.home_page')}
                    color='primary'
                    size='large'
                    iconPosition='end'
                    variant='contained'
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  />
                  <CustomButton
                    fullWidth
                    text={t('error_boundary.reload_page')}
                    color='primary'
                    size='large'
                    iconPosition='end'
                    variant='contained'
                    onClick={() => {
                      window.location.reload()
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <AuthFooter />
          </Container>
        </Box>
      </Suspense>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
    fontWeight: '400',
    marginBottom: '32px',
    paddingTop: '30px',
    paddingBottom: '15px'
  },
  onclick: {
    cursor: 'pointer',
    color: '#0077FF',
    marginBottom: '10px'
  },
  container: {
    maxWidth: '1280px',
    background: theme.palette.background.default,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
    borderRadius: '30px',
    width: '100%',
    margin: '1.875rem auto'
  },
  content: {
    padding: '2rem',
    borderRadius: '30px',
    overflow: 'hidden'
  },
  errorContent: {
    margin: 'auto',
    maxWidth: '560px',
    position: 'relative'
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      maxWidth: '40%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        marginBottom: '5px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  seeDetails: {
    marginBottom: '32px',
    padding: '10px',
    backgroundColor: theme.palette.blue.light
  }
})

export default withStyles(styles, { withTheme: true })(ErrorBoundary)
