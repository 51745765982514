import { gettext, ngettext } from '@resuelve/malinchex'

const STRINGS = {
  rtd: {
    company_name: 'Resuelve tu Deuda',
    schedule: {
      days: 'lunes a viernes',
      hours: '9:00 am a 7:00 pm'
    },
    download_app: 'Descarga la app de Resuelve',
    countries: {
      mexico: 'México',
      spain: 'España',
      colombia: 'Colombia'
    }
  },
  numeral: {
    abbreviations: {
      thousand: '\nmil',
      million: '\nMDP'
    }
  },
  country_selector: {
    modal: {
      description:
        'Hemos detectado que deseas **cambiar de país**, recuerda que los datos que visualizas dentro del sitio se adaptan a el país seleccionado',
      country_selected: 'País seleccionado',
      ok: 'Entiendo'
    }
  },
  page_404: {
    go_home: 'Ir a inicio'
  },
  commons: {
    and: 'y',
    see_more: 'Ver más',
    no_records: 'Sin registros'
  },
  error_boundary: {
    title: 'Hubo un error al cargar esta página.',
    see_details: 'Haz clic para obtener detalles del error.',
    reload_page: 'Recarga esta página',
    home_page: 'Ir a página principal'
  },
  payment_reminder: {
    button: 'Descargar talón',
    status: {
      bad: {
        title:
          '**¡Realiza el pago de tu apartado!** Con tus apartados podrás llegar más rápido a la meta',
        text:
          'Recuerda que el **%{day} de %{nextPaymentMonth}** es tu próximo apartado, %{dayLeft} **%{daysToNextPayment} %{days}**'
      },
      good: {
        title:
          '**¡Realiza el pago de tu apartado!** Te encuentras un paso más cerca del objetivo',
        text:
          'Recuerda que el **%{day} de %{nextPaymentMonth}** es tu próximo apartado, %{dayLeft} **%{daysToNextPayment} %{days}**'
      },
      regular: {
        title:
          '**¡No lo dejes pasar!** Aún estás a tiempo de acercarte al objetivo',
        text:
          'El **%{day} de %{nextPaymentMonth}** es la fecha en la que debes realizar tu próximo apartado, %{dayLeft} **%{daysToNextPayment} %{days}**'
      },
      graduated: {
        title:
          '**¡Muchas felicidades, %{usernames}!** Terminaste con éxito tu programa',
        text:
          'Eres la muestra de que **con compromiso y dedicación ¡todo es posible!**'
      }
    }
  },
  timeline: {
    status: {
      bad: {
        title: '¡Aún estás a tiempo!',
        text:
          'Recuerda que pagando a tiempo podrás llegar más rápido a la meta.'
      },
      good: {
        title: '¡Realiza tu apartado!',
        text:
          'Recuerda que tu ahorro es el motor de nuestras negociaciones para conseguir el mejor descuento.'
      },
      regular: {
        title: '¡Que no se te pase!',
        text:
          'No lo dejes al último. Recuerda hacer tu apartado antes de la fecha límite.'
      },
      graduated: {
        title: '¡Muchas felicidades, %{usernames}!',
        text:
          'Terminaste con éxito tu programa, tú eres la muestra de que con compromiso y dedicación ¡todo es posible!'
      }
    },
    card: {
      title: 'Tu avance',
      last_update: 'Última actualización:'
    },
    calendar: {
      days_left: (_, count) => ngettext('falta', 'faltan', count),
      days: (_, count) => ngettext('día', 'días', count),
      next_payment: 'para tu próximo apartado'
    },
    paid_debts: {
      label: (_, count) => ngettext('1 completo', '%{count} completos', count),
      in_negotiation: 'Tus deudas en negociación:\n',
      congratulation: '¡Felicidades! Has pagado tu deuda %{debts} ¡Sigue así!'
    },
    events: {
      contract_signing: 'Firma del contrato',
      program_start: 'Inicio del programa',
      paid_debts: 'Deudas pagadas',
      monthly_payments_made: 'Tus apartados',
      latest_activity: 'En negociación',
      program_graduation: 'Finaliza tu programa'
    },
    add_new_debt: 'Agregar nueva deuda'
  },
  sidebar: {
    title: 'Tu resumen',
    updated_to: 'Actualizado al\n',
    total_debt: 'Total de tu deuda:',
    current_balance: 'Tu ahorro:',
    program_status: 'Estatus de tu programa:',
    program_antiquity: 'Antigüedad en el programa:',
    deadline: 'Fecha límite de apartado:',
    last_payment: 'Último apartado recibido:',
    monthly_amount: 'Monto de apartado mensual:',
    months: (_, count) => ngettext('1 mes', '%{count} meses', count),
    days: (_, count) => ngettext('1 día', '%{count} días', count),
    active: 'Activo',
    graduated: 'Graduado',
    na: 'N/A',
    drop_requested: 'En trámite de baja',
    early_drop: 'Baja el mismo mes',
    partial_credit: 'Con crédito parcial'
  },
  banner: {
    title: 'Descarga la app',
    description: '**Descarga la app en tu dispositivo**'
  },
  header: {
    reference: 'Tu número de referencia es:',
    referenceInfo:
      'Con este número podrás realizar depósitos a tu cuenta con cualquiera de las opciones que tenemos para ti.',
    referenceExtraInfo:
      'Para más información **descarga tu “Talón de Aportaciones”**'
  },
  navigation: {
    home: 'Inicio',
    movements: 'Movimientos',
    debts: 'Deudas',
    plan: 'Plan',
    payment_reference: 'Talón de Aportaciones',
    contact: 'Contáctanos',
    settings: 'Configuración',
    logout: 'Cerrar sesión',
    menu: 'Menú'
  },
  dashboard: {
    helpButton: {
      text: '¿Necesitas ayuda?'
    },
    recent_movements_card: {
      title: 'Tus movimientos recientes',
      title_modal: 'Tus movimientos'
    },
    my_debts_card: {
      title: 'Tus deudas'
    },
    error: 'Ocurrió un error, por favor inténtalo de nuevo.',
    error_push:
      'Para recibir notificaciones, debes activar el permiso en tu navegador.',
    onboarding_modal: {
      latest_update: 'Última actualización',
      skip: 'Omitir',
      step_0:
        'Para visualizar cómo va el estatus de tu programa, verás una línea de tiempo de tus aportaciones y tus deudas en negociación, en tiempo real.',
      step_1:
        'En esta sección visualizarás el estatus de tus deudas, si tienes descuentos y cuánto te falta para poderlas liquidar.',
      step_2:
        'En esta sección verás reflejados tus pagos mes con mes, divididos por comisiones y apartados.',
      step_3:
        'Aquí podrás visualizar un resumen de la información que tienes dentro del programa Resuelve tu Deuda',
      start: 'Comenzar'
    }
  },
  movements: {
    balance: 'Tu ahorro',
    alert:
      'Tus últimos movimientos pueden tomar hasta **2 días hábiles** en reflejarse.',
    label: (_, count) =>
      ngettext('1 movimiento', '%{count} movimientos', count),
    filters_label: 'Mostrar:',
    filters_deposits: 'Apartados',
    filters_comissions: 'Comisiones',
    filters_all: 'Todos',
    deposit_label: 'Tu aportación',
    commision_label: 'Comisión RTD'
  },
  debts: {
    see_settlement_details: 'Ver detalles de liquidación',
    filters: {
      all: 'Ver todas las deudas'
    },
    tip_text_top: 'Con tus apartados podremos conseguir mejores descuentos.',
    tip_text_bottom:
      '**Te enviaremos una notificación cuando puedas liquidar.**',
    statusLabel: 'Estatus',
    discount_today: 'Mi descuento',
    lastest_activity: 'Última actividad:',
    valid_until: 'Válido hasta',
    debt_total: 'Total de tu deuda:',
    total_debt: 'Total de deuda',
    last_update: 'Actualización',
    to_settlement: 'Por liquidar',
    in_process: 'En proceso',
    in_process_na: 'Sin actividad',
    in_process_tooltip:
      '¡No te preocupes! Todo nuestro equipo está trabajando para conseguirte el mejor descuento.',
    status: {
      new: 'Nueva',
      negotiation: 'En negociación',
      paid: 'Pagada',
      liquidation: 'En liquidación',
      default: 'Otro',
      cancelled: 'Cancelada',
      closed: 'Cerrada',
      non_closed_account: 'Cuenta no cerrada',
      non_closed_account_payment: 'Pago confirmado',
      liquidation_structured_payment: 'En liquidación P.E.',
      liquidation_portfolio_payment: 'En liquidación P.P.',
      paid_structured_payment: 'Pagada con P.E.',
      liquidation_outside_of_program: 'Liquidación fuera del programa'
    },
    see_details: 'Ver detalle',
    messages: {
      new: {
        title: 'Cuenta nueva',
        text:
          'Por ser una cuenta nueva aún no hemos negociado descuentos para ti.'
      },
      medium: {
        title: 'Sigue creciendo tu ahorro',
        text:
          'Al realizar tus apartados a tiempo podremos iniciar la fase de negociación.'
      },
      high: {
        title: 'En fase de negociación',
        text:
          'Con el ahorro que acumules podremos conseguir los mejores descuentos ¡sigue haciendo tus apartados!'
      },
      paid: {
        title: '¡Felicidades!',
        text: 'Lograste pagar esta deuda.'
      }
    }
  },
  credit_repair_sub_states: {
    label: {
      drop_requested: 'Baja en proceso',
      drop: 'Baja del programa',
      definitive_drop: 'Baja del programa - definitiva',
      graduated: 'Graduado',
      program_opening: 'Apertura del programa',
      saving_opening: 'Apertura del ahorro',
      saving_progresive: 'Ahorro progresivo',
      saving_incomplete: 'Ahorro incompleto',
      saving_paused: 'Ahorro en pausa'
    },
    text: {
      drop_requested: '',
      drop: '',
      definitive_drop: '',
      graduated:
        '¡Felicidades! Lo logramos, ahora eres un graduado de Resuelve. Gracias por tu compromiso con el programa, disfruta tu libertad financiera.',
      program_opening:
        '¡Felicidades! El camino hacia tu libertad financiera ha comenzado, con tus aportaciones mes con mes lograremos liquidar tus deudas.',
      saving_opening:
        '¡Bien! Comenzaste tu ahorro mes con mes para liquidar tus deudas y con tus aportaciones encontraremos los mejores descuentos para ti.',
      saving_progresive:
        '¡Sigue así! Tu ahorro mensual es la mejor herramienta para obtener mejores descuentos para ti.',
      saving_incomplete:
        'Entendemos el pequeño atraso en el pago de tu apartado. Recuerda, para no tener problemas con tu programa debes tener tus apartados al corriente.',
      saving_paused:
        'Tu ahorro está en pausa debido al atraso que presentas, tu programa puede comenzar a tener problemas. Si tienes dudas, contáctanos.'
    }
  },
  debt_sub_states: {
    label: {
      debt_assignment: 'Asignación de deuda',
      projecting_negotiation: 'Proyectando negociación',
      negotiation_strategy: 'Estrategia de negociación',
      preparing_negotiation: 'Preparando negociación',
      negotiation: 'Deuda en negociación',
      earned_discount: 'Descuento conseguido',
      liquidation_structured_payment: 'Pago estructurado',
      liquidation_in_process: 'Liquidación en proceso',
      liquidated: 'Deuda liquidada',
      liquidated_with_credit: 'Deuda liquidada con crédito',
      closed: 'Deuda cerrada',
      refinancing: 'Deuda en refinanciación',
      cancelled: 'Cancelada'
    },
    text: {
      debt_assignment:
        'Te asignamos un negociador, él te acompañará durante tu programa y te asesorará ante cualquier duda.',
      projecting_negotiation:
        'Tu negociador te asesorará en cuanto a los diferentes descuentos que se obtendrán de tus acreedores.',
      negotiation_strategy:
        'Trabajamos en un plan para conseguir el mejor descuento. ¡Estamos cerca de negociar! ¿Tienes dudas? Contáctanos',
      preparing_negotiation:
        'Estamos listos para negociar tu deuda si la respuesta de la entidad es favorable, nos comunicaremos contigo. ¿Tienes dudas? Contáctanos',
      negotiation:
        'Si tenemos noticias nuevas te avisaremos. El progreso en tu ahorro nos ayuda con la negociación, ¡no te detengas!.',
      earned_discount:
        'Logramos un gran descuento para tu deuda, acéptala. ¡Estás cerca de tu libertad financiera!',
      liquidation_structured_payment:
        'Actualmente llevas %{pending_payments_total}/%{payments_total} pagos.¡Sigue avanzando con tus pagos para liquidar tu deuda con el acreedor!',
      liquidation_in_process:
        'Estamos realizando la liquidación de tu deuda %{bank} con un %{discount}, esto puede tomar unos días dependiendo del acreedor.',
      liquidated:
        'Cuentas con una garantía RTD y en un par de días te enviaremos tu diploma de liquidación a tu correo.',
      liquidated_with_credit:
        'Absorbimos tu deuda con el acreedor, nuestro equipo de crédito se pondrá en contacto contigo para solucionar cualquier duda.',
      closed:
        'Tu deuda oficialmente está cerrada, solicita tu carta finiquito con el acreedor de tu antigua deuda. ¡Disfruta tu libertad financiera!',
      refinancing: 'La deuda se encuentra en refinanciación.',
      cancelled: ''
    }
  },
  plan: {
    title: 'Mi plan',
    months_label: 'meses',
    monthly_payment: 'Apartado mensual',
    last_payment: 'Fecha del último apartado recibido',
    payment_date_limit: 'Fecha límite de apartado',
    every_month: 'de cada mes',
    payment_receipts_text_phone:
      '¿Sabías que también puedes domiciliar tus apartados? Llámanos al **%{phone}** para activarlo dentro de tu programa.',
    payment_receipts_text_whatsapp:
      'Recuerda que puedes enviarnos tus **comprobantes de pago** directamente desde **WhatsApp** al',
    date_separator: 'de',
    domiciliationErrorMessage:
      'No se pudo procesar la solicitud, intente en unos minutos.',
    domiciliation: 'Actualiza tus \ndatos de domiciliación',
    domiciliation_promotion:
      'Domicilia tu apartado y recibe **100 pesos de descuento** en tu primer apartado.'
  },
  form_validations: {
    email_required: 'Ingresa un correo válido',
    six_digits_verification: 'Ingresa el código de 6 dígitos',
    ten_digits_phone_number: 'Ingresa los 10 dígitos de tu teléfono',
    required_field: 'Este campo es requerido',
    phone_number_or_email_required:
      'Por favor escribe un número de celular de 9 a 10 dígitos o un correo válido',
    password_required: 'La contraseña debe ser mayor o igual a 8 caracteres',
    password_format: 'El formato de la contraseña es inválido'
  },
  greeting_message: {
    good_morning: 'Buenos días',
    good_afternoon: 'Buenas tardes',
    good_evening: 'Buenas noches'
  },
  see_more: {
    text: 'Ver más...'
  },
  page_not_found: {
    text:
      'No pudimos encontrar la página que estabas buscando. Por favor inténtalo más tarde.'
  },
  forms: {
    continue: 'Continuar',
    secure: 'Tus datos quedarán protegidos'
  },
  goBack: {
    text: 'Regresar'
  },
  remove_session: {
    text: 'Regresar',
    confirm_dialog: {
      title: 'Hasta pronto',
      text: '¿Estás seguro que deseas salir de la sesión?',
      confirm_button: 'Sí, volver al inicio',
      cancel_button: 'No, estaba probando'
    }
  },
  logout: {
    confirm_dialog: {
      title: 'Cerrar sesión',
      text: '¿Estás seguro que deseas salir?',
      confirm_button: 'Sí, continuar',
      cancel_button: 'Cancelar'
    }
  },
  forgot_password: {
    button: '¿Olvidaste tu contraseña?',
    title: 'No te preocupes, %{usernames}',
    text:
      'Para generar una nueva contraseña, sólo ingresa tu **número de celular** o **correo**.'
  },
  change_password: {
    title: 'Cambia tu contraseña',
    subtitle: 'Ingresa una nueva contraseña para acceder a tu cuenta.',
    label: 'Contraseña',
    confirmation_label: 'Confirma tu contraseña',
    errors: {
      not_equal: 'Para continuar, las contraseñas deben ser iguales.',
      error: 'Ocurrió un error al guardar la contraseña, inténtalo de nuevo'
    },
    messages: {
      saved: 'La nueva contraseña se ha guardado correctamente.'
    }
  },
  partial_credit: {
    modal: {
      title: 'Crédito activo',
      text:
        'Hemos detectado que tienes un crédito activo, así que por el momento el seguimiento es con tu analista de crédito. ¡Pero no te preocupes! Seguimos negociando tus deudas restantes. Continúa pagando puntualmente para poder liquidarlas lo antes posible.\n\nSi tienes alguna duda, comunícate a **Soporte** y con gusto te ayudaremos.'
    }
  },
  refinancing: {
    modal: {
      title: 'Refinanciación Activa',
      text:
        'Hemos detectado que tienes una refinanciación. Por ahora no podrás ver tu estatus **¡No te preocupes!** \n\nAunque no puedas entrar seguimos trabajando en la liquidación de tus deudas. Cualquier duda que tengas, **no dudes en contactarnos**.'
    }
  },
  support_employee: {
    sign_in: {
      section: {
        dialog: '¡Hola! Soy tu asesora de soporte \nen **Resuelve**',
        text:
          'Te acompañaré en tu proceso de registro, ahora puedes seguir tu programa de forma práctica y segura desde **Mi Espacio Web**.'
      },
      floating: {
        dialog:
          '¡Hola! Soy tu asesor de soporte en **Resuelve**. Ahora puedes seguir tu programa de forma práctica y segura desde **Mi Espacio Web**.'
      }
    },
    login: {
      section: {
        dialog: 'Estás a sólo un paso para\n entrar a **Mi Espacio Resuelve**',
        text:
          'En caso de no recordar tu contraseña no te preocupes, puedes generar una nueva, solo da clic en **¿Olvidaste tu contraseña?** E ingresa una nueva.'
      },
      floating: {
        dialog:
          'En caso de no recordar tu contraseña, no te preocupes; puedes generar otra. Sólo selecciona **¿Olvidaste tu contraseña?** e ingresa una nueva.'
      }
    },
    forgot_password: {
      section: {
        dialog:
          'Sigue las instrucciones para\n generar de nuevo tu contraseña.',
        text:
          'Activa **recordar datos de inicio de sesión** para que no tengas que recordar tu correo o contraseña cada vez que entres al panel.'
      },
      floating: {
        dialog:
          'A todos nos pasa. Solamente sigue las instrucciones para generar de nuevo tu contraseña.'
      }
    },
    validate_otp: {
      section: {
        dialog: {
          phone: 'Escribe el código que te\n enviamos por SMS a tu celular',
          email: 'Escribe el código que te\n enviamos por correo'
        },
        text: {
          email:
            'Si no recibes el código al tercer intento, selecciona **No recibí mi código** y te lo volveremos a enviar. Como tip, procura revisar tu bandeja de correo no deseado.',
          phone:
            'Si no recibes el código al tercer intento, selecciona **No recibí mi código** y te lo volveremos a enviar.'
        }
      },
      floating: {
        dialog: {
          phone:
            'Escribe el código que te enviamos. Si no recibes el código al tercer intento, selecciona **No recibí mi código** y te lo volveremos a enviar.',
          email:
            'Escribe el código que te enviamos por correo. Si no recibes el código al tercer intento, selecciona **No recibí mi código** y te lo volveremos a enviar. Como tip, procura revisar tu bandeja de correo no deseado.'
        }
      }
    },
    create_password: {
      section: {
        dialog: 'Te recomendamos crear una contraseña difícil de adivinar',
        text:
          'Por motivos de seguridad, no uses tu cumpleaños, números consecutivos (123456) ni la palabra “password” para crear tu contraseña.'
      },
      floating: {
        dialog:
          'Te recomendamos crear una contraseña difícil de adivinar. Por motivos de seguridad, no uses tu cumpleaños, números consecutivos (123456) ni la palabra “password” para crear tu contraseña.'
      }
    }
  },
  sign_in: {
    modals: {
      liquidation: {
        title: '¡Lo lograste, %{username}!',
        confirm_text: 'Ver detalles',
        text:
          'Tu adeudo con **%{financialEntity}** ya puede ser liquidado con un descuento de **%{discount}**',
        congratulations: '¡Felicidades %{username}!',
        in_process:
          'Ya estamos trabajando en la liquidación de tu deuda, pronto recibirás más información.'
      },
      liquidation_details: {
        title: 'Detalles de la liquidación',
        financial_entity: 'Adeudo con',
        initial_debt: 'Deuda inicial',
        account_number: 'No de cuenta',
        traded_percentage: 'Porcentaje negociado',
        discount: 'de descuento',
        contact_support: 'Contactar un asesor',
        amount: 'Monto a liquidar',
        commission: 'Comisión Resuelve',
        commission_pending: 'Comisión pendiente',
        total: 'Total a pagar',
        email: {
          subject:
            'Cliente con ref. %{reference} quiere liquidar deuda %{bankName} %{lastDigits}',
          body:
            '%0D%0A%0D%0AQuiero liquidar la deuda y conocer el proceso de liquidación.%0D%0A%0D%0ASaludos'
        }
      },
      user_inactive: {
        title: '¿Aún no realizas tu primer apartado?',
        text:
          'No olvides dar tu primer apartado para continuar con **la activación de tu programa**, ya que tu acceso quedará listo hasta **2 días hábiles** después de haberlo realizado.'
      },
      user_drop: {
        title: 'Reparadora dada de baja',
        text:
          'Hemos detecteado que tu cuenta se encuentra dada de baja, así que por el momento en caso de contar con alguna duda deberás contactarte con servicio al cliente',
        support:
          'Si tienes alguna duda, comunícate a **Soporte** y con gusto te ayudaremos'
      },
      user_deprecated: {
        title: 'Reparadora deprecada',
        text:
          'Hemos detectado que tu cuenta se encuentra deprecada, esto quiere decir que no se concluyó tu proceso para convertirte en cliente.',
        support:
          'En caso de querer continuar con el proceso o si tienes alguna duda puedes hacerlo contactando al servicio al cliente.'
      }
    },
    errors: {
      not_found:
        'La información proporcionada no coincide con ninguna cuenta registrada.',
      error_try_again: 'Ocurrió un error, por favor inténtalo de nuevo.'
    },
    form: {
      title: '¡Bienvenido(a) a\n**Mi espacio Resuelve**!',
      text:
        'Ingresa tu **correo o celular**. ¡No te preocupes! Tus datos están protegidos.',
      label: 'Número celular o correo de resuelve',
      remember_session: 'Recordar datos de inicio de sesión',
      forgot_access: '¿Olvidaste tu correo o número celular?'
    }
  },
  login: {
    errors: {
      validate_account:
        'Ocurrió un error al validar tu cuenta, verifica tu contraseña e intenta nuevamente.'
    },
    form: {
      text:
        'Ingresa tu **contraseña**. ¡No te preocupes! Tus datos están protegidos. ',
      label: 'Contraseña'
    },
    text:
      'Ahora ya puedes seguir tu programa en donde quiera que estés de forma práctica y segura desde **Mi Espacio Resuelve**.'
  },
  auth_loader: {
    title: 'Perfecto',
    text: 'Todo en orden, estamos cargando tu programa.'
  },
  support: {
    need_help: '¿Necesitas ayuda?',
    modals: {
      title: '¿Necesitas ayuda?',
      text:
        'No te preocupes, estamos para ayudarte. ¿Con qué se relaciona más tu consulta?',
      customer_service: {
        title: 'Dudas sobre mi programa',
        text:
          'Si tienes dudas sobre tu saldo, el estatus de tus deudas, o sobre tus apartados y movimientos, por favor contáctanos:',
        buttons: {
          mail: {
            title: 'Escríbenos'
          }
        }
      },
      technical_support: {
        title: 'Soporte técnico',
        text:
          'Si no puedes iniciar sesión o cambiar tu contraseña, por favor manda un correo a:'
      }
    }
  },
  otp: {
    request: {
      resended_sms: 'Te hemos enviado un nuevo código a tu teléfono móvil',
      resended_email: 'Te hemos enviado un nuevo código a tu correo electrónico'
    },
    validation: {
      title: 'Por tu seguridad',
      description_phone:
        'Acabamos de enviar un código a este número de celular para generar tu contraseña:',
      description_email:
        'Acabamos de enviar un código a este correo para generar tu contraseña:',
      info:
        'Este código será **válido por 5 minutos**, por favor ingrésalo a continuación:',
      error: 'No se ha podido verificar el código. Intenta nuevamente.',
      resend: 'No recibí mi código'
    }
  },
  password: {
    title: '¡Validación exitosa!',
    text: 'Ahora sólo crea tu **contraseña** para ingresar de forma segura:',
    back_confirmation: {
      title: '¿Estás seguro de que deseas salir de esta página?',
      description:
        'Al momento de salir de esta página se perderá confirmación del código.',
      confirm_button: 'Sí, regresar',
      cancel_button: 'No, permanecer'
    },
    info: 'Tu contraseña debe contener:',
    form: {
      validation_char_length: 'Mínimo 6 caracteres',
      error: 'Ocurrió un error al guardar la contraseña, inténtalo de nuevo'
    }
  },
  footer: {
    text: 'Resuelve tu Deuda ® Todos los derechos reservados.',
    secure:
      'Tu información es confidencial y está completamente protegida por certificados de seguridad.',
    terms_conditions: 'Términos y condiciones',
    cookies_policy: 'Política de cookies',
    privacy_policy: 'Aviso de privacidad',
    data_policy: 'Solicitud ARCO',
    download_app: 'Descarga la app:'
  }
}

const t = (path, params, count) => {
  const translation = path
    .split('.')
    .reduce((acc = '', key = '') => acc && acc[key], STRINGS)

  switch (typeof translation) {
    case 'function':
      return translation(params, count)
    case 'string':
      return gettext(translation, params)
    default:
      return `**${path}**`
  }
}

export default t
