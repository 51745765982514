import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    padding: '15px 10px',
    maxHeight: '48px',
    borderRadius: '24px',
    fontSize: '1rem',
    lineHeight: '19px',
    fontWeight: 500
  },
  spinner: {
    marginLeft: '1rem',
    color: theme.palette.primary.contrastText
  }
}))

const SpinnerAdornment = props => (
  <CircularProgress className={props.className} size={20} />
)

/**
 * @typedef {'outlined' | 'contained' | 'text' } Variant
 * @typedef {'default' | 'inherit' | 'primary' | 'secondary'} Color
 * @typedef {'small' | 'medium' | 'large'} Size
 * @typedef {'start' | 'end' } Position
 * @typedef{{
 * text: string
 * color?: Color
 * variant?: Variant
 * withIcon?: function
 * iconPosition?: Position
 * disabled?: boolean
 * fullWidth?: boolean
 * size?: Size
 * onClick?: function
 * textStyle: object
 * }} Props
 */
const CustomButton = ({
  dataTestId = '',
  text,
  variant,
  color = 'primary',
  withIcon = false,
  iconPosition,
  disabled = false,
  fullWidth = false,
  disableRipple = false,
  size,
  onClick,
  loading,
  textStyle = null
}) => {
  const classes = useStyles(color)
  return (
    <Button
      data-test-id={dataTestId}
      disabled={disabled}
      classes={{
        root: classes.root,
        text: textStyle
      }}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      startIcon={withIcon && iconPosition === 'start' && withIcon}
      endIcon={withIcon && iconPosition === 'end' && withIcon}
      size={size}
      disableRipple={disableRipple}
      onClick={event => onClick && onClick(event)}
    >
      {text} {loading && <SpinnerAdornment className={classes.spinner} />}
    </Button>
  )
}

export default CustomButton
