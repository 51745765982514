export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName, browserVersion

  if (/chrome|crios|crmo/i.test(userAgent)) {
    browserName = 'Chrome'
    browserVersion = userAgent.match(/(?:chrome|crios|crmo)\/(\d+)/i)[1]
  } else if (/firefox|fxios/i.test(userAgent)) {
    browserName = 'Firefox'
    browserVersion = userAgent.match(/(?:firefox|fxios)\/(\d+)/i)[1]
  } else if (
    /safari/i.test(userAgent) &&
    !/chrome|crios|crmo/i.test(userAgent)
  ) {
    browserName = 'Safari'
    browserVersion = userAgent.match(/version\/(\d+)/i)[1]
  } else if (/edg/i.test(userAgent)) {
    browserName = 'Edge'
    browserVersion = userAgent.match(/edg\/(\d+)/i)[1]
  } else if (/opr|opera/i.test(userAgent)) {
    browserName = 'Opera'
    browserVersion = userAgent.match(/(?:opr|opera)\/(\d+)/i)[1]
  } else {
    browserName = 'Unknown'
    browserVersion = 'Unknown'
  }

  return {
    name: browserName,
    version: browserVersion
  }
}
