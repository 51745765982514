import React from 'react'
// Utils
import { getCurrentLocale } from 'utils/locale'

/**
 * @typedef{{
 * locale: array
 * }} Props
 */
const SupportingByLocale = ({ children, locale = [] }) => {
  const currentLocale = getCurrentLocale()

  if (!locale || !locale.includes(currentLocale)) return null
  return <>{children}</>
}

export default SupportingByLocale
