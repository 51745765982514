import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// Material UI
import { makeStyles, styled } from '@material-ui/core/styles'
import { Select, MenuItem, InputBase, Box, Typography } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
// Components
import Modal from 'components/molecules/modal'
import BoldedText from 'components/molecules/boldedText'
import CustomButton from 'components/molecules/button'
// Utils
import { getCurrentLocale, saveCurrentLocale } from 'utils/locale'
import { MX, ES, CO, DEFAULT_LOCALE } from 'utils/const'
// i18n
import t from 'i18n'

const COUNTRIES = [
  {
    name: t('rtd.countries.mexico'),
    pathfile: './assets/svg/country_selector/icono_mexico.svg',
    value: MX
  },
  {
    name: t('rtd.countries.spain'),
    pathfile: './assets/svg/country_selector/icono_espana.svg',
    value: ES
  },
  {
    name: t('rtd.countries.colombia'),
    pathfile: './assets/svg/country_selector/icono_colombia.svg',
    value: CO
  }
]

const SelectorInputStyled = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiSelect-icon': {
    color: '#FFF'
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid #FFF',
    fontSize: 16,
    color: '#FFFFFF',
    padding: '8px 36px 8px 12px'
  },
  '& .MuiSvgIcon-root': {
    marginRight: '5px'
  }
}))

const CountrySelectedModal = ({
  open = false,
  closeAction = null,
  countrySelected = ''
}) => {
  const classes = useStyles()

  if (!countrySelected && !open) return null

  const country = COUNTRIES.find(country => country.value === countrySelected)

  const handleNavigate = () => {
    window.location.href = country?.value || DEFAULT_LOCALE
    saveCurrentLocale(country?.value)
  }

  return (
    <Modal
      open={open}
      closeAction={() => closeAction && closeAction()}
      title={
        <Box className={classes.logo}>
          <img
            src='./assets/svg/logo_resuelve.svg'
            alt={t('rtd.company_name')}
            height='auto'
          />
        </Box>
      }
      content={
        <Box className={classes.countrySelectorContainer}>
          <BoldedText
            textStyle={classes.changeCountryText}
            text={t('country_selector.modal.description')}
            boldedTextStyle={classes.changeCountryTextBolded}
          />
          <Typography className={classes.countrySelectedText}>
            {t('country_selector.modal.country_selected')}
          </Typography>
          <Box className={classes.countrySelectedContent}>
            <img
              width='40px'
              alt=''
              className={classes.countryImageSelected}
              src={country.pathfile}
            />
            <span>{country.name}</span>
          </Box>
          <Box className={classes.countrySelectedButtonContent}>
            <CustomButton
              fullWidth
              text={t('country_selector.modal.ok')}
              color='primary'
              size='large'
              iconPosition='end'
              variant='contained'
              onClick={handleNavigate}
            />
          </Box>
        </Box>
      }
    />
  )
}

const CountrySelector = () => {
  const classes = useStyles()
  const locale = getCurrentLocale()
  const [showModal, setShowModal] = useState(false)
  const [countrySelected, setcountrySelected] = useState('')
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('/co')) {
      setcountrySelected(CO)
    }
    if (location.pathname.includes('/mx')) {
      setcountrySelected(MX)
    }
    if (location.pathname.includes('/es')) {
      setcountrySelected(ES)
    }
  }, [location.pathname])

  const changeCountry = ({ target }) => {
    const { value } = target
    if (value !== locale) {
      setcountrySelected(value)
      setShowModal(true)
    }
  }

  return (
    <>
      <CountrySelectedModal
        open={showModal}
        closeAction={() => setShowModal(false)}
        countrySelected={countrySelected}
      />
      <Select
        IconComponent={KeyboardArrowDown}
        value={locale}
        input={<SelectorInputStyled />}
        onChange={changeCountry}
      >
        {COUNTRIES.map(country => (
          <MenuItem value={country.value} key={country.value}>
            <Box className={classes.itemContent}>
              <img
                width='20px'
                alt=''
                className={classes.countryImage}
                src={country.pathfile}
              />
              <span>{country.name}</span>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  itemContent: {
    display: 'flex'
  },
  countryImageSelected: {
    marginRight: '25px'
  },
  countryImage: {
    marginRight: '10px'
  },
  logo: {
    textAlign: 'center'
  },
  changeCountryText: {
    textAlign: 'center',
    marginBottom: '36px'
  },
  changeCountryTextBolded: {
    color: theme.palette.secondary.dark
  },
  countrySelectedText: {
    textAlign: 'center',
    marginBottom: '16px'
  },
  countrySelectorContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  countrySelectedContent: {
    marginBottom: '45px',
    textAlign: 'center',
    padding: '5px 35px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  countrySelectedButtonContent: {
    width: '40%',
    alignSelf: 'center'
  }
}))

export default CountrySelector
