import React from 'react'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Link, Typography } from '@material-ui/core'
// i18n
import t from 'i18n'
// Utils
import { APP_URL_STORES } from 'utils/const'
import { getCurrentLocale, getContactByReference } from 'utils/locale'
// Components
import SupportingByLocale from 'components/organisms/supportingByLocale'
import CountrySelector from 'components/organisms/countrySelector'

const AuthFooter = () => {
  const classes = useStyles()
  const locate = getCurrentLocale()
  const termsConditions = getContactByReference(
    'footer_contacts.terms_conditions'
  )
  const appleStoreUrl = APP_URL_STORES.apple[locate] || ''
  const cookiesPolicy = getContactByReference('footer_contacts.cookies_policy')
  const privacyPolicy = getContactByReference('footer_contacts.privacy_policy')
  const dataPolicy = getContactByReference('footer_contacts.data_policy')

  return (
    <Grid container className={classes.content}>
      <Grid container className={classes.itemsContent}>
        <Grid item sm={12} md={1} xs={12} className={classes.logoContent}>
          <a href='/'>
            <img
              src='./assets/svg/logo_resuelve_blanco.svg'
              alt={t('rtd.company_name')}
              height='auto'
            />
          </a>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Link
            target='_blank'
            href={termsConditions}
            variant='body1'
            color='textSecondary'
          >
            {t('footer.terms_conditions')}
          </Link>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Link
            target='_blank'
            href={cookiesPolicy}
            variant='body1'
            color='textSecondary'
          >
            {t('footer.cookies_policy')}
          </Link>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Link
            target='_blank'
            href={privacyPolicy}
            variant='body1'
            color='textSecondary'
          >
            {t('footer.privacy_policy')}
          </Link>
        </Grid>
        <SupportingByLocale locale={['mx']}>
          <Grid item xs={6} sm={6} md={2}>
            <Link
              target='_blank'
              href={dataPolicy}
              variant='body1'
              color='textSecondary'
            >
              {t('footer.data_policy')}
            </Link>
          </Grid>
        </SupportingByLocale>
        <Grid item xs={6} sm={6} md={2}>
          <CountrySelector />
        </Grid>
      </Grid>
      <Box className={classes.footerText}>
        <Grid container className={classes.linksContent}>
          <Grid item xs={12} sm={12} md={6}>
            {t('footer.text')}
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.appsContent}>
            <Typography className={classes.downloadAppText}>
              {t('footer.download_app')}
            </Typography>
            <Link href={APP_URL_STORES.google} target='_blank' rel='noopener'>
              <img
                alt={t('rtd.download_app')}
                src='./assets/svg/icono_google_blanco.svg'
                height='auto'
              />
            </Link>
            <Link href={appleStoreUrl} target='_blank' rel='noopener'>
              <img
                alt={t('rtd.download_app')}
                src='./assets/svg/icono_apple_blanco.svg'
                height='auto'
              />
            </Link>
            <Link href={APP_URL_STORES.huawei} target='_blank' rel='noopener'>
              <img
                alt={t('rtd.download_app')}
                src='./assets/svg/icono_huawei_blanco.svg'
                height='auto'
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    color: theme.palette.primary.contrastText,
    padding: '30px 74px 0 88px',
    background: theme.palette.secondary.main,
    borderRadius: '0 0 25px 25px',
    fontSize: '1rem',
    lineHeight: '24px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '25px',
      borderRadius: '0 30px 0 0'
    }
  },
  downloadAppText: {
    fontSize: '0.75rem',
    color: theme.palette.background.default,
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  linksContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  appsContent: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '1rem',
    '& img': {
      marginRight: '10px'
    },
    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      marginBottom: '45px',
      justifyContent: 'space-between'
    }
  },
  itemsContent: {
    borderBottomColor: theme.palette.background.default,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    paddingBottom: '20px',
    '& div:nth-child(n + 2)': {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        marginTop: '10px'
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        marginBottom: '10px',
        marginTop: '10px',
        '& a': {
          fontSize: '0.875rem'
        }
      }
    }
  },
  termConditionCookiesPolicy: {
    fontWeight: 500,
    maxWidth: '180px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial',
      width: '100%'
    }
  },
  logoContent: {
    display: 'grid',
    '&::before': {
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.green.main,
      width: '32px',
      height: '4px',
      marginBottom: '10px'
    }
  },
  footerText: {
    fontWeight: 300,
    padding: '24px 0',
    width: '100%'
  }
}))

export default AuthFooter
