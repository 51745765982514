import Api from 'api'

class OtpService extends Api {
  validate = (uid, input, code) => {
    const data = {
      data: {
        uid,
        input,
        code
      }
    }
    const url = `${process.env.REACT_APP_CLIENTS_API_URL}/otp/validate`
    return this.post(url, data)
  }

  request = input => {
    const data = { input }
    const url = `${process.env.REACT_APP_CLIENTS_API_URL}/otp/request`
    return this.post(url, data)
  }
}

export default OtpService
