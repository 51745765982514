import Api from 'api'
import { getBrowserInfo } from 'utils/browser'
import { getOSInfo } from 'utils/os'

class UserService extends Api {
  saveUserPlatform = (userId = '', token = '') => {
    const { name, version } = getBrowserInfo()
    const osName = getOSInfo()

    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/user-platforms/${userId}/register`,
      data: {
        platform: {
          os: osName,
          browser: {
            name,
            version
          },
          project: 'rtd'
        },
        type: 'web'
      },
      headers: {
        'Content-type': 'Application/json',
        Authorization: token
      }
    }

    return this.request(options)
  }
}

export default UserService
