import React from 'react'
// Material components
import { makeStyles, Typography, Link } from '@material-ui/core'

// Components
import CustomButton from 'components/molecules/button'

/**
 * @typedef{{
 * text: string
 * textStyle?: object
 * boldedTextStyle?: object,
 * link?: object
 * }} Props
 */
const BoldedText = ({
  text,
  textStyle = null,
  boldedTextStyle = null,
  action = null,
  link = {}
}) => {
  const formattedText = callback => {
    if (!text) {
      return ''
    }

    const regex = new RegExp(/\*\*(.*?)\*\*/, 'gmi')
    const splittedText = text.split(regex)

    for (let i = 1, length = splittedText.length; i < length; i += 2) {
      splittedText[i] = callback(splittedText[i], i)
    }

    return splittedText
  }

  const textType = ({ text, index }) => {
    const boldedStyle = boldedTextStyle || classes.boldedText

    if (action) {
      return (
        <CustomButton
          key={index}
          variant='text'
          text={text}
          onClick={action}
          textStyle={[classes.textButton, boldedStyle].join(' ')}
        />
      )
    }

    return (
      <span key={index} className={boldedStyle}>
        {text}
      </span>
    )
  }

  const classes = useStyles()
  return (
    <Typography className={textStyle || classes.text}>
      {formattedText((text, index) => textType({ text, index }))}{' '}
      {link && (
        <Link target='_blank' href={link.url}>
          {link.value}
        </Link>
      )}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '1rem',
    marginBottom: '32px',
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap'
  },
  boldedText: {
    fontWeight: 500,
    color: theme.palette.secondary.main
  },
  textButton: {
    padding: 0
  }
}))

export default BoldedText
