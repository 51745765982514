import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Zoom } from '@material-ui/core'

const Loader = () => {
  const classes = useStyles()
  const nodeRef = useRef(null)

  return (
    <Box className={classes.root}>
      <Zoom in style={{ transitionDelay: '300ms' }} ref={nodeRef}>
        <img
          className={classes.loader}
          alt='Resuelve tu Deuda'
          src='./assets/resuelve-icon.svg'
        />
      </Zoom>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  loader: {
    width: 90,
    height: 'auto',
    margin: '0 auto'
  }
}))

export default Loader
