import React from 'react'
// Material components
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Hidden,
  ButtonBase,
  Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloseOutlined, ExpandMoreOutlined } from '@material-ui/icons'

/**
 * @typedef {'sm' | 'xs' | 'lg' | 'md' | 'lg' | 'xl' } MaxWidth
 * @typedef{{
 * title: string
 * content: string
 * maxWidth: string
 * open?: boolean
 * closeAction?: function
 * styleContent?: useStyles
 * }} Props
 */
const Modal = ({
  open = false,
  maxWidth = 'sm',
  closeAction,
  title,
  content,
  styleContent = null,
  titleStyleContent = null
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      keepMounted
      onClose={closeAction}
      aria-describedby='modal-description'
    >
      <DialogContent>
        {closeAction && (
          <Box className={classes.action}>
            <ButtonBase className={classes.actionButton} onClick={closeAction}>
              <Hidden smDown>
                <CloseOutlined
                  className={classes.iconClose}
                  color='primary'
                  fontSize='large'
                />
              </Hidden>
              <Hidden mdUp>
                <ExpandMoreOutlined color='primary' fontSize='large' />
              </Hidden>
            </ButtonBase>
          </Box>
        )}
        <Container className={[classes.wrap, styleContent].join(' ')}>
          <Typography
            color='primary'
            component='h1'
            variant='h1'
            className={[classes.title, titleStyleContent].join(' ')}
          >
            {title}
          </Typography>
          {content}
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '1rem'
  },
  iconClose: {
    width: '24px',
    color: theme.palette.primary.light
  },
  content: {
    fontSize: '1rem',
    marginBottom: 0,
    '& strong': {
      color: theme.palette.secondary.main
    }
  },
  tab: {
    paddingTop: '2rem',
    backgroundColor: theme.palette.secondary.main,
    borderBottomRightRadius: '2rem',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 'auto',
      height: '150px',
      position: 'relative',
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      borderBottomRightRadius: '1rem'
    }
  },
  action: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: '1.625rem 2rem 1rem 0'
  },
  actionButton: {
    borderRadius: '2rem'
  },
  wrap: {
    padding: '3rem 2rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0rem 2.2rem 3rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '2rem'
    }
  }
}))

export default Modal
